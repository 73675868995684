import Types from '@sport1/news-styleguide/Types'

export enum TeaserSizeTypes {
    BIG = 'BIG',
    MID = 'MID',
    SMALL = 'SMALL',
    LIST = 'LIST',
    HORIZONTAL_BIG = 'HORIZONTAL_BIG',
}

export enum MediathekTeaserTypes {
    VIDEO_TEASER = 'VIDEO_TEASER',
    PORTRAIT_TEASER = 'PORTRAIT_TEASER',
    PODCAST_TEASER = 'PODCAST_TEASER',
    ICON_TEASER = 'ICON_TEASER',
    HERO_TEASER = 'HERO_TEASER',
    HERO_OPTION = 'HERO_OPTION',
    LIST = 'LIST',
}

export type TeaserSize =
    | TeaserSizeTypes.BIG
    | TeaserSizeTypes.MID
    | TeaserSizeTypes.SMALL
    | TeaserSizeTypes.LIST
    | TeaserSizeTypes.HORIZONTAL_BIG

export type MediathekTeaserType =
    | MediathekTeaserTypes.VIDEO_TEASER
    | MediathekTeaserTypes.PORTRAIT_TEASER
    | MediathekTeaserTypes.PODCAST_TEASER
    | MediathekTeaserTypes.ICON_TEASER
    | MediathekTeaserTypes.HERO_TEASER
    | MediathekTeaserTypes.HERO_OPTION
    | MediathekTeaserTypes.LIST

export type ComponentProps = Types.TeaserProps & Types.BasicTeaserType & Types.MediathekTeaserProps
